<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Villa Booking For Owner
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Villa Booking For Owner</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6"></div>
                    <div class="float-end col-6">
                      <router-link
                        :to="{ name: 'booking' }"
                        class="btn btn-gradient-primary float-end"
                      >
                        Back
                      </router-link>
                    </div>
                  </div>
                </div>
                <section>
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <input type="hidden" name="id" />
                        <div class="flex-grow-1">
                          <div class="p-1 sectionBlock" id="section-block">
                            <div class="row">
                              <div class="col-6">
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Check in - Check out date</label
                                  >
                                  <Datepicker
                                    type="date"
                                    @closed="dateChange()"
                                    @cleared="resetForm()"
                                    :min="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    v-model="form.rangeDate"
                                    format="dd-MM-yyyy"
                                    value-format="dd-MM-yyyy"
                                    :min-date="new Date()"
                                    modelAuto
                                    range
                                    multiCalendars
                                    multiCalendarsSolo
                                    placeholder="Check in - Check out date"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="p-1 mt-2 sectionBlock"
                            v-if="villaList.length"
                          >
                            <div class="row">
                              <div class="col-6">
                                <label class="form-label" for="basic-addon-name"
                                  >Villa</label
                                >
                                <Select2
                                  :options="villaList"
                                  :settings="{
                                    placeholder: 'Choose Villa',
                                  }"
                                  v-model="select_villa"
                                />
                                <div
                                  class="invalid-feedback fw-bold fst-italic"
                                  :style="{ display: 'none' }"
                                >
                                  Please Choose Payment Mode.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="form.check_in_date && select_villa"
                          >
                            <div class="col-12 text-center">
                              <button
                                type="submit"
                                class="btn btn-primary me-1 data-submit"
                                :disabled="disabled"
                                @click="bookVilla()"
                              >
                                Book Now
                              </button>
                              <button
                                type="reset"
                                :disabled="disabled"
                                @click="resetForm()"
                                class="btn btn-outline-secondary"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import Select2 from "vue3-select2-component";

export default {
  name: "BookingAdd",
  components: {
    Datepicker,
    Select2,
  },
  data() {
    return {
      villaList: [],
      form: new Form({
        id: [],
        rangeDate: "",
        check_in_date: "",
        check_out_date: "",
      }),
      checkInDateError: "none",
      errors: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      disabled: false,
      payurl: null,
      select_villa: null,
    };
  },
  beforeCreate() {
    var element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
    }

    var element2 = document.querySelector(".modal-open");
    if (element2) {
      element2.style.overflow = "unset";
    }
  },
  async mounted() {
    this.$store.dispatch("getPaymentModeList");
  },
  methods: {
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          const start_date = moment(date[0]).format("YYYY-MM-DD");
          const end_date = moment(date[1]).format("YYYY-MM-DD");
          this.form.check_in_date = start_date;
          this.form.check_out_date = end_date;

          const dateA = moment(end_date);
          const dateB = moment(start_date);
          this.form.days = dateA.diff(dateB, "days") + 1;
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
          this.form.days = 1;
        }
      }
    },
    dateChange() {
      this.dateResult();
      if (this.form.rangeDate) {
        axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/get-villa-owners",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            this.villaList = response.data.data;
          })
          .catch((e) => {
            console.log("e: ", e);
          });
      }
    },
    resetForm() {
      this.form.rangeDate = "";
      this.form.check_in_date = "";
      this.form.check_out_date = "";
      this.form.payment_mode_id = "";
      this.villaList = [];
      this.villa_booking = [];
      this.clickVillaId = [];
      this.form.persons = [];
      this.form.persons.push({
        detail_id: "",
        user_id: "",
        barcode_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile: "",
        id_proof: "",
        is_primary: true,
        user_type: "Guest",
      });
      this.bookingResult = {
        villa_price: 0,
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
        coupon_amount: 0,
      };
      this.villa_type = {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottages: {
          qty: 0,
          person: 0,
        },
      };
      this.reserve_error = {
        platinum: {
          error: null,
        },
        diamond: {
          error: null,
        },
        cottages: {
          error: null,
        },
      };
    },
    async bookVilla() {
      const villa_id = this.select_villa ? parseInt(this.select_villa) : 0;
      const villa_obj = this.villaList.find((x) => x.id === villa_id);

      if (villa_obj) {
        this.disabled = true;
        var apiUrl = process.env.VUE_APP_API_URL + "/book-myself-admin";
        var payload = {
          check_in_date: this.form.check_in_date,
          check_out_date: this.form.check_out_date,
          villa_type_id: villa_obj.villa_type_id,
          villa_id: villa_obj.id,
          user_id: villa_obj.user_id,
          check_type: "Owner",
        };

        await axios
          .post(apiUrl, payload, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data?.message);
              this.select_villa = null;
              this.dateChange();
            } else {
              this.$toast.error(response.data?.message);
            }
            setTimeout(() => {
              this.disabled = false;
            }, 500);
          });
      }
    },
  },
  computed: {},
};
</script>
<style>
.card-datatable {
  overflow-x: auto;
}

.villa_card_wrapper {
  width: 100%;
  display: flex;
}
.noslotavailable {
  padding: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.5;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}

.btn-add-new span {
  margin-top: 5px;
}

.btn-add-new {
  display: flex;
}

.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}

.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}

.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}

.trash-view i:hover {
  transform: translateY(-2px);
}

.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}

.error {
  color: #ea5455;
  font-size: 0.857rem;
}

.form-check {
  padding-left: 0.785rem !important;
}

.status-badge {
  position: relative;
  cursor: pointer;
}

.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}

.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}

.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}

.statusZindex {
  z-index: -1;
}

.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}

.mobileSearchDiv {
  position: relative;
}

.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}

.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.search-mobile:focus {
  border-color: #7367f0 !important;
}

.wrapper-option {
  display: flex;
}

.applybtn {
  margin-top: 23px;
  width: 100%;
}

.bk_title_info_info_main_row {
  margin: 0px -10px;
}

.bk_title_info_info_main_row {
  display: flex;
  flex-wrap: wrap;
}

.bk_title_info_info_main_row .bk_title_info_info {
  width: 33.33%;
  height: 100%;
  padding: 10px;
}

.bk_title_info_info_inr {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 14px #dceae8;
}

.bk_title_info {
  display: flex;
  padding: 12px;
  flex-direction: column;
}

.bk_title_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 7px;
}

.bk_content {
  padding-top: 10px;
}

.vila_title {
  text-transform: capitalize;
  margin-bottom: 10px;
  text-align: left;
  padding: 0 !important;
}

.vila_title_info,
.main_qun ul {
  display: flex;
  padding: 2px 0px;
  align-items: center;
}

.vila_title_info {
  font-size: 12px;
  color: #42aea3;
}

.vila_title_info svg {
  width: 25px;
  height: 12px;
}

.vialla_qty_main_inner {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 14px #dceae8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px 15px;
  display: flex;
}

.vialla_qty_main {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vialla_qty_main:nth-child(1) {
  margin-right: 5px;
}

.vialla_qty_main:nth-child(2) {
  margin-left: 5px;
}

.main_class_inner_sd.select_image {
  width: 100%;
  position: relative;
}

.main_class_inner_sd.select_image svg {
  left: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.vialla_qty_main select {
  width: 100%;
  height: 32px;
  padding-left: 20px;
}

p.reverse_error {
  padding: 10px;
  color: red;
}

.overflow_auto {
  overflow: auto !important;
}
</style>
